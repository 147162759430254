<template>
  <div class="portfolio">
    <narrow-content title="Portfolio作品集">
      <!-- <portfolio-item
        v-for="(item, index) in projects"
        :key="index"
        v-bind="item"
      ></portfolio-item> -->
      <portfolio-item></portfolio-item>
      <portfolio-item></portfolio-item>
      <portfolio-item></portfolio-item>
    </narrow-content>
    <get-in-touch></get-in-touch>
  </div>
</template>
<script>
import PortfolioItem from "@/components/PortfolioItem";
import NarrowContent from "@/components/NarrowContent";
import GetInTouch from "@/components/GetInTouch";
import projects from '@/assets/js/projects.json'

export default {
  components: {
    PortfolioItem,
    NarrowContent,
    GetInTouch,
  },
  data() {
    return {
      projects,
    };
  },
};
</script>
